
//按需引入 已引入折线图  饼图 柱图
import { defineComponent, onMounted, watch } from 'vue'
import * as echarts from 'echarts/core'
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
} from 'echarts/components'
import { LineChart, PieChart, BarChart } from 'echarts/charts'
import { UniversalTransition, LabelLayout } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'
echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition,
  BarChart,
  PieChart,
  LabelLayout,
])
export default defineComponent({
  props: {
    id: {
      type: String, //容器id
    },
    // echarts配置
    options: {
      type: Object,
      default: () => {
        return null
      },
    },
  },
  emits: ['getChart'],
  setup(props, { emit }) {
    let myChart: echarts.ECharts
    watch(
      () => props.options,
      (newVal) => {
        // 数据变动 重新绘制
        myChart.setOption(newVal, true)
        console.log(newVal)
      },
      { deep: true }
    )
    const resize = () => {
      // console.log(props.options)
      myChart?.resize()
    }
    onMounted(() => {
      //获取容器
      let chartDom = document.getElementById(props.id as string)
      // 初始化
      myChart = echarts.init(chartDom as HTMLElement)
      // 绘制
      props.options && myChart.setOption(props.options)
      emit('getChart', myChart)
    })
    return {
      resize,
    }
  },
})
